<template>
  <div style="width: 100vw;height: 100vh;position: relative">
    <div style="width: 100%;position: absolute">
      <p class="project-code-style">{{ select_project_name }}</p>
      <div style="width: 100%;text-align: center;margin-top: 20px">
        <p class="logout" @click="clickExchangeProject">切换项目</p>
        <p class="logout" style="margin-left: 20px" @click="doLogout">退出登录</p>
      </div>
      <div class="list-container">
        <div v-for="(item,index) in formatList" :key="item._id.$id">
          <div class="article-item" @click.stop="goDetail(item)">
            <div class="article-title">{{ item.name }}</div>
            <img
                :class="{
            rightIcon: item.children.length === 0,
            rotateIcon: item.children.length !== 0 && item.isShow,
          }"
                :src="
            item.children.length > 0
              ? require('../assets/arrow.png')
              : require('../assets/go.png')
          "
            />
          </div>
          <Transition name="slide-fade">
            <div v-if="item.children.length > 0 && item.isShow">
              <div>
                <div
                    v-for="subItem in item.children"
                    :key="subItem._id.$id"
                    @click.stop="goDetail(subItem)"
                >
                  <div class="sub-article-item">
                    <div class="sub-article-title">{{ subItem.name }}</div>
                    <img
                        :class="{
                    rightIcon: subItem.children.length === 0,
                    rotateIcon: subItem.children.length !== 0 && subItem.isShow,
                  }"
                        :src="
                    subItem.children.length > 0
                      ? require('../assets/arrow.png')
                      : require('../assets/go.png')
                  "
                    />
                  </div>
                  <Transition name="slide-fade">
                    <div v-if="subItem.children.length > 0 && subItem.isShow">
                      <div
                          v-for="lastItem in subItem.children"
                          :key="lastItem._id.$id"
                          @click.stop="goDetail(lastItem)"
                      >
                        <div class="last-article-item">
                          <div class="sub-article-title">{{ lastItem.name }}</div>
                          <img
                              :class="{ rightIcon: lastItem.children.length === 0 }"
                              :src="
                          lastItem.children.length > 0
                            ? require('../assets/arrow.png')
                            : require('../assets/go.png')
                        "
                          />
                        </div>

                        <Transition name="slide-fade">
                          <div v-if="lastItem.children.length > 0 && lastItem.isShow">
                            <div
                                v-for="last1Item in lastItem.children"
                                :key="last1Item._id.$id"
                                @click.stop="goDetail(last1Item)"
                            >
                              <div class="last-article-1-item">
                                <div class="sub-article-title">{{ last1Item.name }}</div>
                                <img
                                    :class="{ rightIcon: last1Item.children.length === 0 }"
                                    :src="
                          last1Item.children.length > 0
                            ? require('../assets/arrow.png')
                            : require('../assets/go.png')
                        "
                                />
                              </div>
                            </div>
                          </div>
                        </Transition>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <div class="launch-weapp" v-show="showLaunchWeapp">
      <div class="launch-weapp-content">
        <div style="width: 100%;text-align: center">
          <p style="color: #333333;font-size: 18px;font-weight: 500">即将打开小程序</p>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 20px;align-items: center;justify-content: center">
          <p class="launch-weapp-btn-cancel" @click="showLaunchWeapp=false">取消</p>
          <div style="width: 20px"/>
          <div>
            <!--  en = gh_845acf49fdb8    fbla = gh_a40f157eb4ae-->
            <wx-open-launch-weapp
                id="launch-btn"
                username="gh_845acf49fdb8"
                :path="sectionInfo.content">
              <script type="text/wxtag-template">
                <style>
                  .weapp-btn {
                    cursor: pointer;
                    text-align: center;
                    line-height: 42px;
                    width: 120px;
                    background: #092E7F;
                    height: 42px;
                    border-radius: 6px 6px 6px 6px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #FFFFFF;
                  }
                </style>
                <div class="weapp-btn">继续</div>
              </script>
            </wx-open-launch-weapp>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showExchangeProjectDialog" width="100%" :modal="true" :show-close="false"
               :modal-append-to-body="true"
               custom-class="showCustomDialogStyle">
      <div class="dialog-container">
        <el-select v-model="select_project_code" class="project-select" :popper-append-to-body="false">
          <el-option v-for="(project,index) in projects" :key="index" :label="project.project_name"
                     :value="project.project_code"></el-option>
        </el-select>
        <div style="width: 100%;text-align: left;margin-top: 20px">
          <div class="confirm-btn" @click="confirmSelectProject">确认</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getElecHandbookByProjectCode, getSignPackage} from "@/api/project";
import {arrayToTree} from "@/utils/index";
import {clearUser, getProjectCode, getSignCache, setProjectCode, setSignCache} from '../utils/store'
import {getLoginProjects} from '../api/project'
import {Loading} from 'element-ui'

export default {
  data() {
    return {
      select_project_code: getProjectCode(),
      showExchangeProjectDialog: false,
      select_project_name: '',
      projects: [],
      project_code: getProjectCode(),
      showLaunchWeapp: false,
      formatList: [],
      sectionInfo: {
        content: ''
      },
      signPackage: {
        appId: '',
        timestamp: '',
        nonceStr: '',
        signature: ''
      }
    };
  },
  mounted() {
    let that = this
    document.getElementById('launch-btn').addEventListener('launch', function (e) {
      that.showLaunchWeapp = false
      that.$forceUpdate()
    });
    document.getElementById('launch-btn').addEventListener('error', function (e) {
      that.showLaunchWeapp = false
      that.$forceUpdate()
    });
    this.fetchElecSections();
    this.requestProjects()
    this.requestSignPackage()
  },
  methods: {

    confirmSelectProject() {
      setProjectCode(this.select_project_code)
      this.project_code = this.select_project_code
      this.showExchangeProjectDialog = false
      this.fetchElecSections()
      this.handleSelectProject()
    },

    clickExchangeProject() {
      this.showExchangeProjectDialog = true
    },

    doLogout() {
      clearUser()
      this.$router.replace('/')
    },
    requestProjects() {
      getLoginProjects().then((res) => {
        this.projects = res.data.data
        this.handleSelectProject()
      })
    },
    handleSelectProject() {
      for (let i = 0; i < this.projects.length; i++) {
        if (this.projects[i].project_code == this.project_code) {
          this.select_project_name = this.projects[i].project_name
          break
        }
      }
    },
    launchWeapp() {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.signPackage.appId, // 必填，公众号的唯一标识
        timestamp: this.signPackage.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.signPackage.nonceStr, // 必填，生成签名的随机串
        signature: this.signPackage.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData'], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      })
      wx.ready(() => {
      })
      wx.error((res) => {
        this.$message.error('调起小程序异常，点击重试！')
        this.showLaunchWeapp = false
        setSignCache('')
        this.requestSignPackage()
        console.log('error res ;' + JSON.stringify(res))
      })
    },
    requestSignPackage() {
      let cache = getSignCache()
      let enableRequest = true
      if (cache && cache != undefined && cache != null && cache != '') {
        let values = cache.split(";")
        let time = values[0]
        //1个小时的有效期
        let current_time = new Date().getTime()
        let oneHour = 1 * 60 * 60 * 1000
        if (current_time >= (time + oneHour)) {
          //过期啦，重新请求
          setSignCache('')
        } else {
          this.signPackage.appId = values[1]
          this.signPackage.timestamp = values[2]
          this.signPackage.nonceStr = values[3]
          this.signPackage.signature = values[4]
          enableRequest = false
        }
      }
      if (!enableRequest) {
        console.log('不需要请求啦！')
        return
      }
      console.log('请求获取！')
      getSignPackage().then((res) => {
        const result = res.data
        this.signPackage.appId = result.appId
        this.signPackage.timestamp = result.timestamp
        this.signPackage.nonceStr = result.nonceStr
        this.signPackage.signature = result.signature
        let current_time = new Date().getTime()
        let content = current_time + ';' + result.appId + ';' + result.timestamp + ';' + result.nonceStr + ';' + result.signature
        setSignCache(content)
      })
    },
    async fetchElecSections() {
      const loadingInstance = Loading.service({fullscreen: true, spinner: 'el-icon-loading'})
      try {
        this.formatList = []
        const res = await getElecHandbookByProjectCode({
          project_code: this.project_code,
        });
        let result = res.data
        if (result.code == 0) {
          const sections = result.data['sections'];
          this.formatData(sections);
        } else {
          this.$message.success('暂无数据')
        }
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        console.log(error);
      }
    },
    goDetail(item) {
      if (item.children.length === 0) {
        let content_type = item.content_type
        if (content_type == 'xiaochengxu') {
          this.sectionInfo = item
          this.showLaunchWeapp = true
          this.launchWeapp()
        } else {
          this.$router.push({
            path: "/detail",
            query: {
              id: item.id,
            },
          });
        }
      } else {
        //简单处理
        item.isShow = !item.isShow

        // console.log('item :' + JSON.stringify(item))
        // let level = parseInt(item.section_level)
        // if (level === 1) {
        //   const index = this.formatList.findIndex(
        //       (i) => i.id === item.id
        //   );
        //   this.$set(this.formatList, index, {
        //     ...this.formatList[index],
        //     isShow: !this.formatList[index].isShow,
        //   });
        // }
        // if (level === 2) {
        //   const index = this.formatList.findIndex(
        //       (i) => i.id === item.id
        //   );
        //   const subIndex = this.formatList[index].children.findIndex(
        //       (j) => j.id === item.id
        //   );
        //   this.$set(this.formatList[index].children, subIndex, {
        //     ...this.formatList[index].children[subIndex],
        //     isShow: !this.formatList[index].children[subIndex].isShow,
        //   });
        // }
      }
    },

    formatData(items) {
      let currentNode = 0;
      let currentSonNode = 0;
      const formatData = items.map((item) => {
        const level = parseInt(item.level)
        if (level === 1) {
          currentNode = item._id.$id;
          item.id = item._id.$id;
          item.pid = "0";
          item.isShow = false;
        }
        if (level === 2) {
          currentSonNode = item._id.$id;
          item.id = item._id.$id;
          item.pid = currentNode;
          item.isShow = false;
        }
        if (level === 3) {
          item.pid = currentSonNode;
          item.id = item._id.$id;
          item.isShow = false;
        }
        if (level === 4) {
          item.pid = currentSonNode;
          item.id = item._id.$id;
          item.isShow = false;
        }
        return item;
      });
      this.formatList = arrayToTree(formatData);
    },
  },
};
</script>

<style>
.showCustomDialogStyle {
  width: 100vw;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}
</style>

<style lang="css" scoped>

.confirm-btn {
  display: inline-block;
  cursor: pointer;
  padding: 8px 18px;
  text-align: center;
  box-sizing: border-box;
  background-color: #213A8B;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}

.dialog-container {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  background-color: white;
}

.project-select {
  width: 100%;
}

/deep/ .el-dialog__body {
  padding: 0px;
}

/deep/ .el-input__inner {
  height: 50px;
  line-height: 50px;
}


.project-code-style {
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  background-color: #213A8B;
  color: white;
  font-size: 16px;
}

.logout {
  display: inline-block;
  cursor: pointer;
  padding: 8px 18px;
  text-align: center;
  box-sizing: border-box;
  background-color: #213A8B;
  border-radius: 18px;
  color: white;
  font-size: 20px;
}

.launch-weapp {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.launch-weapp-content {
  border-radius: 8px;
  width: 80%;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
}

.launch-weapp-btn-cancel {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  width: 120px;
  background-color: white;
  height: 42px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #092E7F;
  font-size: 16px;
  font-weight: 600;
  color: #092E7F;
}

.list-container {
  width: 100%;
  position: absolute;
  padding: 24px 20px;
  box-sizing: border-box;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.article-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px 16px;
  background-image: url("../assets/article-bg.png");
  background-size: 100%;
  border-radius: 10px;
  margin: 10px 0;
}

.article-item img {
  width: 18px;
  height: 18px;
}

.article-item .rightIcon {
  width: 6px;
  height: 12px;
}

.article-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;

}

.sub-article-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 25px 70px -10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 0px 15px 15px 15px;
}

.sub-article-title {
  padding-right: 5px;
  box-sizing: border-box;
  flex: 1;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
}

.sub-article-item img {
  width: 18px;
  height: 18px;
}

.sub-article-item .rightIcon {
  width: 6px;
  height: 12px;
}

.last-article-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 25px 70px -10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 30px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.last-article-1-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 25px 70px -10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 45px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.last-article-item img {
  width: 18px;
  height: 18px;
}

.last-article-item .rightIcon {
  width: 6px;
  height: 12px;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.rotateIcon {
  transform: rotate(-180deg);
  transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>
